import Head from 'next/head'

import * as e from '@fe/components/Elements'
import Page from '@fe/components/common/Page'
import { Provider as CartProvider } from '@fe/providers/cart'
import ErrorPage404 from '@fe/views/errors/404'

const Custom404 = () => (
  <>
    <Head>
      <title>Oops! Page not found</title>
      <meta content='noindex,nofollow' name='robots' />
    </Head>
    <CartProvider>
      <Page
        component={() => (
          <e.Layout.PageSection>
            <ErrorPage404 />
          </e.Layout.PageSection>
        )}
        disableAuth
        disableMenu
        disableRedirectToHome
        isPaymentPage
      />
    </CartProvider>
  </>
)

export default Custom404
